import { ListHead, Outer, ShadowContainer, TableContainer } from 'components';
import React, { useState } from 'react';
import { getGender, Main, MainLayout, saveLayouts, useMainLayout } from './utils';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TableElement } from 'components/UI/atoms/Containers';
import styled from 'styled-components';
import { isManagedOnAdmin } from 'pages/ManageMainComponent/utils';
import fetchingAPI from 'api/fetchingAPI';

const Container = styled(TableElement)`
  border-bottom: 1px solid #201c1c;
  color: black;
  text-decoration: none;
  ${({ fixed = false }: { fixed?: boolean }) => fixed && 'background:#C4C4C4;'}
`;

const SecondaryButton = styled(Button)`
  outline: none;
  &:focus {
    outline: none;
    box-shadow: none;
    background: #6c757d;
  }
`;

const typeMap: { [key in Main.Type]: string } = {
  BIG_BANNER: '대배너',
  MAIN_CATEGORIES: '메인 카테고리',
  RECOMMEND_CATEGORY: '최근 본 상품과 같은 카테고리 상품 추천',
  TEXT_BAND_BANNER: '텍스트 배너',
  IMAGE_BAND_BANNER: '이미지 배너',
  PROMOTION_INFO: '프로모션 정보',
  FETCHING_DEAL: '페칭 딜',
  DESIGN_COMPONENT_WITH_IMAGE: '이미지형 컴포넌트',
  DESIGN_COMPONENT_WITHOUT_IMAGE: '텍스트형 컴포넌트',
  RECOMMEND_BRAND: '최근 본 상품과 같은 브랜드 상품 추천',
  SELECT_SHOPS: '편집샵별 상위 상품',
  FETCHING_RANKING: '페칭 랭킹',
  BRAND_RANKING: '브랜드별 상품 랭킹',
  TIME_DEAL: '타임딜',
  SHOWCASE: '브랜딩 영역',
  POPULAR_BRANDS: '브랜드 랭킹',
  REVIEW: '베스트 리뷰',
  NEW_IN: '신상',
  DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY: '텍스트형 탭 컴포넌트',
  DESIGN_COMPONENT_WITH_IMAGE_CATEGORY: '이미지형 탭 컴포넌트',
  NEW_EXHIBITION: '뉴 익스히비션',
};

const ManageMainLayout = ({ mode }: { mode: 'm' | 'w' }) => {
  const {
    state: [layouts, setLayouts],
    reload,
    isLoading,
  } = useMainLayout(mode);
  const [isPending, setIsPending] = useState(false);

  const resetChanges = () => {
    if (window.confirm('모든 변경사항을 초기화하시겠습니까?')) {
      reload();
    }
  };

  const saveChanges = async () => {
    if (window.confirm('변경사항을 저장하시겠습니까?')) {
      setIsPending(true);
      await saveLayouts(mode, [
        ...layouts.map((layout): any => ({ ...layout, requestType: 'UPDATE' })),
      ]);
      await reload();
      setIsPending(false);
    }
  };

  const deleteLayout = async (idx: number, layout: MainLayout) => {
    if (window.confirm('레이아웃을 삭제하시겠습니까? (즉시 저장됩니다.)')) {
      setIsPending(true);
      await saveLayouts(mode, [{ ...layout, idx, requestType: 'DELETE' }]);
      await reload();
      setIsPending(false);
    }
  };

  const goUp = (index: number) => {
    const diff =
      layouts
        .slice(0, index)
        .reverse()
        .findIndex((layout) => !layout.immutable && !layout.fixed) + 1;
    if (diff > 0) {
      setLayouts((p) => {
        [p[index - diff], p[index]] = [p[index], p[index - diff]];
        return p.map((layout, order) => ({ ...layout, order }));
      });
    }
  };

  const goDown = (index: number) => {
    const diff =
      layouts.slice(index + 1).findIndex((layout) => !layout.immutable && !layout.fixed) +
      1;
    if (diff > 0) {
      setLayouts((p) => {
        [p[index + diff], p[index]] = [p[index], p[index + diff]];
        return p.map((layout, order) => ({ ...layout, order }));
      });
    }
  };
  const toggleFix = (order: number) => {
    if (window.confirm(`위치 고정을 변경하시겠습니까?`)) {
      setLayouts((p) =>
        p.map((layout) => ({
          ...layout,
          ...(layout.order === order && { fixed: !layout.fixed }),
        }))
      );
    }
  };
  const toggleActive = (order: number, layout: MainLayout) => {
    if (
      layout.type === 'DESIGN_COMPONENT_WITHOUT_IMAGE' &&
      layout.exhibitionId === null &&
      layout.exhibitionTabId === null &&
      layout.promotionId === null &&
      layout.isActive === false
    ) {
      alert(
        '텍스트형 컴포넌트는 반드시 상품 선택을 해야합니다. 컴포넌트 관리 탭에서 상품 운영 방식을 설정해주세요.'
      );
    } else if (window.confirm('활성 상태를 변경하시겠습니까?')) {
      setLayouts((p) =>
        p.map((layout) => ({
          ...layout,
          ...(layout.order === order && { isActive: !layout.isActive }),
        }))
      );
    }
  };

  if (isLoading)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner animation="border" />
      </div>
    );
  else
    return (
      <Outer>
        <ShadowContainer>
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-xl">{getGender(mode)} 레이아웃 관리</h1>
            <div className="flex gap-3">
              <Button variant="warning" onClick={resetChanges}>
                초기화
              </Button>
              <Button onClick={saveChanges}>
                {isPending ? <Spinner animation="border" /> : '저장하기'}
              </Button>
              <Link to={`/content/main/${mode}/layout/add`}>
                <Button variant="outline-dark">컴포넌트 추가</Button>
              </Link>
              <Link to={`/content/main/${mode}/layout/add/bandbanner`}>
                <Button variant="outline-dark">띠배너 추가</Button>
              </Link>
            </div>
          </div>

          <TableContainer>
            <ListHead />
            {layouts.map((layout, i) => (
              <Container fixed={layout.immutable || layout.fixed}>
                <div>
                  {!layout.immutable && (
                    <Button
                      variant={layout.fixed ? 'dark' : 'light'}
                      onClick={() => toggleFix(layout.order)}
                    >
                      {layout.fixed ? '해제' : '고정'}
                    </Button>
                  )}
                </div>
                <div>
                  {!(layout.immutable || layout.fixed) && (
                    <Button
                      variant="danger"
                      onClick={() => deleteLayout(layout.idx, layout)}
                    >
                      삭제
                    </Button>
                  )}
                </div>
                <div>
                  {!(layout.immutable || layout.fixed) && (
                    <>
                      <SecondaryButton
                        variant="secondary"
                        className="focus:outline-none focus:shadow-none"
                        onClick={() => goUp(layout.order)}
                      >
                        👆
                      </SecondaryButton>{' '}
                      <SecondaryButton
                        variant="secondary"
                        onClick={() => goDown(layout.order)}
                      >
                        👇
                      </SecondaryButton>
                    </>
                  )}
                </div>
                <div>
                  <Button
                    variant={layout.isActive ? 'outline-success' : 'outline-danger'}
                    onClick={() => toggleActive(layout.order, layout)}
                  >
                    {layout.isActive ? '켜짐' : '꺼짐'}
                  </Button>
                </div>
                <div>
                  {isManagedOnAdmin(layout.type) ? (
                    <Link to={`/content/main/${mode}/component/${layout.idx}`}>
                      {layout.name}
                    </Link>
                  ) : (
                    <span>{layout.name}</span>
                  )}
                </div>
                <div>
                  <p>{typeMap[layout.type]}</p>
                </div>
              </Container>
            ))}
          </TableContainer>
        </ShadowContainer>
        {/* <button
          onClick={() => {
            saveLayouts(mode, [
              {
                type: 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY',
                requestType: 'CREATE',
                order: 99,
                name: '테스트용 image 탭 컴포넌트',
                exhibitionId: 441,
                title: '테스트용 이미지 탭 컴포넌트',
                description: '테스트용 이미지 탭 컴포넌트',
                href: '/',
              } as Partial<MainLayout> & {
                requestType: 'UPDATE' | 'CREATE' | 'DELETE';
              },
            ] as any);
          }}
        >
          action
        </button> */}
      </Outer>
    );
};

export default ManageMainLayout;
